import {SORT_ARGS} from '../candidates-const';
import {getMultipleQueryValue} from '@/util';

export default class CandidatesSortModel {
  constructor(query) {
    this.by = [];
    this.desc = [];
    this.ordering = null;
    this._apiAttributes = {
      inQueue: SORT_ARGS.IN_QUEUE,
      failed: SORT_ARGS.FAILED,
      onHold: SORT_ARGS.ON_HOLD,
      candidateName: SORT_ARGS.NAME,
      candidateBirthday: SORT_ARGS.BIRTHDAY,
      status: SORT_ARGS.STATUS,
      accountUsername: SORT_ARGS.CBR_ACCOUNT,
      productName: SORT_ARGS.PRODUCT_NAME,
      reserved: SORT_ARGS.RESERVED_INFO,
      color: SORT_ARGS.COLOR,
      number: SORT_ARGS.NUMBER,
      exam_result: SORT_ARGS.EXAM_RESULT,
      presentie_status: SORT_ARGS.PRESENTIE_STATUS,
      zoom_status: SORT_ARGS.ZOOM_STATUS,
      online_access_days_left: SORT_ARGS.ONLINE_ACCESS_DAYS,
      wiki_access_days_left: SORT_ARGS.WIKI_ACCESS_DAYS
    };
    this._customSorting = {
      reserved: (ordering) => this.setReservedSorting(ordering)
    };
    this._orderingFields = {
      [SORT_ARGS.NAME]: 'candidateName',
      [SORT_ARGS.BIRTHDAY]: 'candidateBirthday',
      [SORT_ARGS.IN_QUEUE]: 'inQueue',
      [SORT_ARGS.FAILED]: 'failed',
      [SORT_ARGS.ON_HOLD]: 'onHold',
      [SORT_ARGS.STATUS]: 'status',
      [SORT_ARGS.CBR_ACCOUNT]: 'accountUsername',
      [SORT_ARGS.PRODUCT_NAME]: 'productName',
      [SORT_ARGS.RESERVED_INFO]: 'reserved',
      [SORT_ARGS.COLOR]: 'color',
      [SORT_ARGS.NUMBER]: 'number',
      [SORT_ARGS.EXAM_RESULT]: 'exam_result',
      [SORT_ARGS.PRESENTIE_STATUS]: 'presentie_status',
      [SORT_ARGS.ZOOM_STATUS]: 'zoom_status',
      [SORT_ARGS.ONLINE_ACCESS_DAYS]: 'online_access_days_left',
      [SORT_ARGS.WIKI_ACCESS_DAYS]: 'wiki_access_days_left'
    };
    this._initByQuery(query);
  }

  _initByQuery(query) {
    for (let sortField in this._apiAttributes) {
      if (this._apiAttributes[sortField] in query) {
        this.by.push(sortField)
        this.desc.push(query[this._apiAttributes[sortField]])
      }
    }
    if (query[SORT_ARGS.ORDERING]) this._initOrdering(query[SORT_ARGS.ORDERING])
    this._serializeQueryBoolean()
  }

  _initOrdering(query) {
    let val = getMultipleQueryValue(query)
    val.forEach(field => {
      let orderingName = field.replace('-', '')
      if (!(orderingName in this._orderingFields)) return

      let fieldVal = field === '-' ? fieldVal = true : fieldVal = false
      this.by.push(this._orderingFields[orderingName])
      this.desc.push(fieldVal)
    })
  }

  _serializeQueryBoolean() {
    if (!this.desc.length) return
    this.desc = this.desc.map(value => {
      if (value === 'false') value = false
      if (value === 'true') value = true
      return value
    })
  }

  getApiData(query) {
    let data = {}

    this._setOrdering(data, query)

    return data
  }

  _getQueueApiData() {
    let index = this.by.findIndex(field => field === 'inQueue')
    if (index < 0) return
    return this._transformQueryBoolean(this.desc[index])
  }

  _transformQueryBoolean(val) {
    if (val === 'false') return false
    if (val === 'true') return true
    return val
  }

  _getFailedApiData() {
    let index = this.by.findIndex(field => field === 'failed')
    if (index < 0) return
    return this._transformQueryBoolean(this.desc[index])
  }

  _getOnHoldApiData() {
    let index = this.by.findIndex(field => field === 'onHold')
    if (index < 0) return
    return this._transformQueryBoolean(this.desc[index])
  }

  _getOrderingApiData() {
    let index = this.by.findIndex(field => field === 'ordering')
    if (index < 0) return
    return this._transformQueryBoolean(this.desc[index])
  }

  _setOrdering(data) {
    let ordering = []
    this.by.forEach(field => {
      if (!(field in this._customSorting)) { // if false - set ordering
        // this._removeSelectedOrdering(this._apiAttributes[field], data)
        let index = this.by.findIndex(selectedField => selectedField === field)
        ordering.push(this._convertBooleanToOrderingFormat(this.desc[index], this._apiAttributes[field]))
      } else if (field in this._customSorting) this._customSorting[field](ordering)
    })
    data[SORT_ARGS.ORDERING] = ordering
  }

  _removeSelectedOrdering(field, data) {
    if (SORT_ARGS.ORDERING in data && (data[SORT_ARGS.ORDERING] !== field || data[SORT_ARGS.ORDERING] !== `-${field}`)) {
      let fieldName = SORT_ARGS.ORDERING.replace('-', '')
      let fieldNameToRemove = this._orderingFields[fieldName]
      let index = this.by[fieldNameToRemove]
      this.by.splice(index, 1)
      this.desc.splice(index, 1)
    }
  }

  _convertBooleanToOrderingFormat(val, field) {
    if (!val) return `-${field}`
    if (val) return field
    return
  }

  _convertBooleanToMultiOrderingFormat(val, field) {
    if (!val) {
      return field.split(',').map(item => `-${item}`).join(',')
    }
    if (val) {
      return field.split(',').map(item => `${item}`).join(',')
    }
    return
  }

  setReservedSorting(ordering) {
    let index = this.by.findIndex(selectedField => selectedField === 'reserved')
    ordering.push(this._convertBooleanToMultiOrderingFormat(this.desc[index], `${SORT_ARGS.JOURNEY_DAY},${SORT_ARGS.RESERVED_INFO},${SORT_ARGS.EXAM_LOCATION}`))
  }
}
