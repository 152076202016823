import { EMAIL_TEMPLATE_ARGS } from "./CandidatesEmailTemplateModel"

export default class CandidatesEmailTemplateValidator{
  constructor() {
    this.apiAttributes = {
      [EMAIL_TEMPLATE_ARGS.LABEL]: 'label',
      [EMAIL_TEMPLATE_ARGS.SUBJECT]: 'subject',
      [EMAIL_TEMPLATE_ARGS.CONTENT]: 'content'
    }
  }

  validate (validator, data) {
    validator.resetFields()
    this._validateLabel(validator, data.label)
    this._validateSubject(validator, data.subject)
    this._validateContent(validator, data.content)
    return validator.isValid()
  }

  _validateLabel(validator, value) {
    validator.validField()
    if(!this._hasValue(value)) 
      validator.invalidField('label', 'Field required')
    if(value.length > 15) 
      validator.invalidField('label', 'Label should contain 15 characters or less')
  }

  _validateSubject(validator, value) {
    if(!this._hasValue(value)) 
      validator.invalidField('subject', 'Field required')
  }

  _validateContent(validator, value) {
    if(!this._hasValue(value)) 
      validator.invalidField('content', 'Field required')
  }

  _hasValue(value) {
    if(!value || !value.length) return false
    return true
  }
}
