export const FAILED_CANDIDATE = {
  ID: 'id',
  NUMBER: 'number',
  LAST_NAME: 'last_name',
  FIRST_NAME: 'first_name',
  NAME: 'name',
  DOB: 'date_of_birth',
  EMAIL: 'email',
  PHONE: 'phone'
}

export default class HandleFailedModel {
  constructor(item = {}) {
    this.ID = item[FAILED_CANDIDATE.ID]
    this.number = item[FAILED_CANDIDATE.NUMBER]
    this.dob = item[FAILED_CANDIDATE.DOB]
    this.email = item[FAILED_CANDIDATE.EMAIL]
    this.firstName = item[FAILED_CANDIDATE.FIRST_NAME]
    this.lastName = item[FAILED_CANDIDATE.LAST_NAME]
    this.name = item[FAILED_CANDIDATE.NAME]
    this.phone = item[FAILED_CANDIDATE.PHONE]
  }
}
