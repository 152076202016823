import api from "@/services/api/api-emailTemplates"
import {CustomEmailTemplateModel, SystemEmailTemplateModel, EMAIL_TEMPLATE_ARGS} from "@/app/admin/modules/candidates/core/models/CandidatesEmailTemplateModel"

export default class CbrAccService {
  constructor(repo) {
    this.repo = repo
  }
  async list(params) {
    return await this.repo.list(params)
    try {
      let res = await api.list()
      let data =  res.map( item => new SystemEmailTemplateModel(item))
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async listCustom(params) {
    return this.repo.listCustom(params)
    try {
      let res = await api.listCustom()
      let data =  res.map( item => new CustomEmailTemplateModel(item))
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async post(data) {
    let _data = {
      [EMAIL_TEMPLATE_ARGS.LABEL]: data.label,
      [EMAIL_TEMPLATE_ARGS.SUBJECT]: data.subject,
      [EMAIL_TEMPLATE_ARGS.CONTENT]: data.content,
    }
    return this.repo.post(_data)
    try {
      let res = await api.post({
        [EMAIL_TEMPLATE_ARGS.LABEL]: data.label,
        [EMAIL_TEMPLATE_ARGS.SUBJECT]: data.subject,
        [EMAIL_TEMPLATE_ARGS.CONTENT]: data.content,
      })
      return new CustomEmailTemplateModel(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async put(ID, data) {
    let _data = {
      [EMAIL_TEMPLATE_ARGS.LABEL]: data.label,
      [EMAIL_TEMPLATE_ARGS.SUBJECT]: data.subject,
      [EMAIL_TEMPLATE_ARGS.CONTENT]: data.content,
    }
    let res = await this.repo.put(ID, _data)
    return res

  }

  async remove(ID) {
    return await this.repo.remove(ID)
  }
}