<template lang="pug">
div
  span.table-date.table-date_additional.table-date_nowrap(v-for="(course, index) in item.confirmedCourses" :key="index")
    template(v-if="index < 2")
      candidate-course-confirmed-item(:item="course")
      template(v-if="!(index === item.confirmedCourses.length - 1)") ,
  template(v-if="item.confirmedCourses.length > 2")
    v-menu(max-width="800px" offset-y bottom content-class="v-menu__content")
      template(v-slot:activator="{on}")
        div.show-all-text.unselectable(v-on="on") show all
      v-list
        v-list-item(v-for="(coursers, index) in item.confirmedCourses" :key="index").min-auto
          v-list-item-title.table-date.table-date_additional
            candidate-course-confirmed-item(:item="coursers")
            template(v-if="!(index === item.confirmedCourses.length - 1)") ,
</template>

<script>
import CandidateCourseConfirmedItem from './CandidateCourseConfirmedItem'

export default {
  components: {
    CandidateCourseConfirmedItem
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.min-auto {
  min-height: auto;
}

.table-date {
  font-size: 10px;
  display: block;

  &_nowrap {
    white-space: nowrap;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }

  &_main {
    font-weight: bold;
  }

  &_additional {
    line-height: 1.3;
    color: $label-color;
    font-weight: 500;
  }
}
</style>
