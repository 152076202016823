<script>
import CandidatesEmailTemplateValidator from "../../core/models/candidatesEmailTemplateValidator"
import errorsMixin from '@/mixins/errors.mixin';

export default {
  render() {
    return this.$scopedSlots.default({
      sendEmail: this.sendEmail
    })
  },

  inject: {
    parentSvc: 'svc'
  },

  mixins: [errorsMixin],

  props: {
    ID: Number,
    formErrors: Object,
    model: Object
  },

  data: () => ({
    templateValidator: new CandidatesEmailTemplateValidator(),
  }),

  methods: {
    async sendEmail(data, callback) {
      try {
        if(!data.readonly && !this.templateValidator.validate(this.formErrors, data)) {
          this.$notify({text: 'Invalid fields', type: 'error'})
          return
        }
        this.processing = true
        let res = await this.parentSvc().sendEmailBulk([this.ID], data)
        if (res.failedCandidates && res.failedCandidates.length) {
          this.showErrorModal(res.error)
          callback()
          return
        }
        callback()
        this.$notify({text: 'Email send', type: 'success'})
        this.$emit('send', data.label)
        return res
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors, 
          apiAttributes: this.templateValidator.apiAttributes,
        });
        this.$notify({text: 'Sending error', type: 'error'})
      }
      finally {
        this.processing = false
      }
    },

  },
}
</script>