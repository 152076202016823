<template lang="pug">
div
  confirmation-dialog(
    v-model="showing"
    @okBtnClicked="acceptHandler"
    @failBtnClicked="toggle"
    title="Accept/KDR"
    descr="Are you sure you want accept/KDR for this candidate?"
    okText="ok"
    failText="cancel"
  )
    template(v-slot:activator="{on}")
      slot(name="activator" :on="on" :toggle="toggle" :accept="accept")
        v-btn(small color='primary' 
          @click="onAcceptClick" 
          :disabled="disabledAccept"
          outlined data-test="c-accept"
        ) Accept/KDR
    
    
</template>
<script>
import { CBR_STATUS } from '../core/candidates-const'
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin],
  props: {
    ID: {
      required: true
    },
    model: Object,
    disabled: Boolean,
    withConfirmationDialog: Boolean
  },
  data: () => ({
    showing: false
  }),

  computed: {
    disabledAccept() {
      if(this.disabled) return true
      if(this.model.status !== CBR_STATUS.NEW) return true
      return false
    },
  },

  inject: ['svc'],

  methods: { 
    toggle() {
      if(this.disabledAccept) return
      this.showing = true
    },

    Svc() {
      return this.svc()
    },

    onAcceptClick() {
      if(this.withConfirmationDialog) return this.toggle()
      this.accept()
    },

    acceptHandler() {
      this.toggle()
      this.accept()
    },

    async accept() {
      try {
        await this.Svc().accept([this.ID])
        this.$notify({text: 'Started accepting/searching KDR', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    }
  },

  components: {
    // acceptDialog: () => import('./CandidateAcceptModal.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>