<template lang="pug">
div
  slot(:openDialog="openDialog")
    v-btn(
      :disabled="disabled"
      @click="openDialog"
      color="primary"
      outlined
      small
    ) Archive
  accept-dialog(
    title="Archive candidate"
    descr="Are you sure you want to archive this candidate?"
    v-model="showing"
    @okBtnClicked="send"
  )
</template>

<script>
import errMixin from "@/mixins/errors.mixin"

export default {
  mixins: [errMixin],

  props: {
    disabled: Boolean,
    ID: {
      required: true
    }
  },

  data: () => ({
    showing :false
  }),

  inject: ['svc'],

  methods: {
    openDialog() {
      this.showing = true
    },

    Svc() {
      return this.svc()
    },
    showModal() {
      this.showing = true
    }, 

    async send() {
      try {
        await this.Svc().remove(this.ID)
        this.showing  = false
        this.$emit('sended')
      } catch (error) {
        console.log(error)
        this.showing = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      
    }
  },

  components: {
    acceptDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>