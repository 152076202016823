import { PAID_MANUALLY, PAYMENT_STATUS } from '@/models/paymentModel';

export default {
  computed: {
    paymentColor() {
      if (this.item.payments.length && this.item.payments[0].status === PAYMENT_STATUS.OPEN) return 'warning';
      else if (this.item.payments.length && this.item.payments[0].label === PAID_MANUALLY && !this.item.payments[0].isArchived) return 'success';
      else if (this.item.is_course_paid) return 'success';
      return 'error';
    },

    hasPackage() {
      return (this.item.course_type === 'online' && this.item.has_package) || (this.item.course_type === 'offline' && this.item.has_package && this.item.is_course_paid);
    },

    notHasPackage() {
      return (this.item.course_type === 'online' && !this.item.has_package ) || (this.item.course_type === 'offline' && !this.item.has_package && this.item.is_course_paid);
    }
  }
};
