<script>
import SentEmailModel, { SENT_EMAIL_ARG } from '../../core/models/candidatesSentEmailModel'

export default {
  render() {
    return this.$scopedSlots.default({
      logs: this.logs,
      loadLogs: this.loadLogs,
      addToLogs: this.addToLogs,
      setTemplateTooltipShowing: this.setTemplateTooltipShowing
    })
  },

  inject: {
    parentSvc: 'svc'
  },

  props: {
    getLogs: Function
  },

  data: () => ({
    list: [],
    logs: []
  }),

  mounted() {
    // this.loadData()
  },

  methods: {
    loadLogs() {
      this.logs = this.getLogs()
    },

    setTemplateTooltipShowing(val) {
      this.templateTooltip = val
      this.loadLogs()
    },

    addToLogs(label) {
      this.logs.unshift(new SentEmailModel( 
        {
          [SENT_EMAIL_ARG.LABEL]: label,
          [SENT_EMAIL_ARG.DATETIME]: Date.now()
        }
      ))
    }
  },
}
</script>