<template lang="pug" functional>
  span(:class="classes").status-box
    .status-box__item.status-box__item_success(v-if="props.value")
    .status-box__item.status-box__item_error(v-else)
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    classes: String
  }
}
</script>

<style lang="scss" scoped>
.status-box {
  display: inline-block;

  &__item {
    width: 7px;
    display: inline-block;
    height: 7px;
    border-radius: 50%;

    &_ {
      &success {
        background-color: #1c7e48;
      }

      &error {
        background-color: $error-check-color;
      }
    }
  }
}
</style>
