import Dayjs from 'dayjs';
import { DATE_FORMAT_HOUR_DEFAULT, DATE_FORMAT_DEFAULT } from '@/util/const';

export default class ReservedExamDTO {
  constructor(item = {}) {
    this.id = item ? item.id : null;
    this.examDate = item ? Dayjs(new Date(item.datetime).toLocaleString("en-US", {timeZone: "Europe/Amsterdam"})).format(DATE_FORMAT_HOUR_DEFAULT) : null;
    this.date = item ? Dayjs(new Date(item.datetime).toLocaleString("en-US", {timeZone: "Europe/Amsterdam"})).format(DATE_FORMAT_DEFAULT) : null;
    this.location = item ? item.location : null;
    this.productName = item ? item.product_name : null;
    this.code = item ? item.reservation_code : null;
    this.journey_was_changed = item ? item.journey_was_changed : null;
    this.message_sent_count = item ? item.message_sent_count : 0
    // this.isZelfExam = item ? item.is_zelf_exam : null
    // this.transportationConfig = item ? new TransportConfigDTO(item.transportation_config) : null
    this.transport_type = item ? item.transport_type : null;
    this.journey = item ? item.journey : null;
  }
}
