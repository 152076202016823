<template lang="pug">
v-data-table(
  :headers="headers"
  :items="list"
  hide-default-footer
  :loading="loading"
  class="app-table"
)
  template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
    .contents
      tr
        td(:class="{'archived': item.isArchived}").paid {{ item.createdAt | formattedDatetime }} / {{ item.paidAt | formattedDatetime }}
        td(:class="{'archived': item.isArchived}").amount {{ item.label }} {{ item.amount }}
        td(:class="{'archived': item.isArchived}").status
          | {{ item.status }}
        td(:class="{'archived': item.isArchived}").text-center.notes
          show-more(:text="item.notes" :height="'40px'" v-if="item.notes")
          span(v-else) -
        td(:class="{'archived': item.isArchived}").action
          slot(name="actions" :payment="item" :isPaid="item.status === PAID")
      template(v-if="item.refunds.length")
        tr(v-for="refund in item.refunds").bg-second
          td(:colspan="headers.length").pb-0
            .d-flex
              div.cell-paid
                div.ml-4  {{ refund.createdAt | formattedDatetime }}
              div.cell-amount.mr-3.text-center {{ refund.label }} -{{ refund.amount }}
              div.cell-status {{ refund.status }}

</template>

<script>
import { PAYMENT_STATUS } from '@/models/paymentModel'
import { PAYMENT } from '../../core/models/candidatesPaymentItemModel'
import CheckColor from "@/components/global/CheckColor.vue"

export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    loading: Boolean
  },
  
  data:() => ({
    headers: [
      { text: 'Created / Paid', value: 'dates', sortable: false },
      { text: 'Amount', value: 'amount', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Notes', value: 'notes', sortable: false, align: 'center' },
      { text: '', value: 'action', sortable: false}
    ],
    PAID: PAYMENT_STATUS.PAID,
  }),

  components: {
    showMore: () => import('@/components/global/showMoreTooltip.vue')
  }
}
</script>

<style lang="scss" scoped>
.app-table .archived {
  color: $log-default;
}

.contents {
  display: contents;
}

.notes {
  width: 20%;
}

.paid {
  width: 30%;
}

.status {
  width: 20%;
}

.amount {
  width: 20%;
}

.action {
  width: 10%;
}

.cell {
  &-paid {
    width: 30%;
  }
  &-amount {
    width: 20%;
  }
  &-status {
    width: 20%;
  }
}


</style>