import Dayjs from "dayjs"

import ReservationRequestDTO, { REQUEST_ARGS } from "./reservationRequestDTO"
import ReservedExamDTO from "./reservedExamDTO.js"
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_HOUR_DEFAULT, DATE_FORMAT_HOUR_SECONDS_DEFAULT, PRODUCT } from "@/util/const"
import SentEmailModel from "../models/candidatesSentEmailModel"
import { PaymentLog } from "@/models/paymentModel"
import CourseModel from "@/app/admin/models/courseModel"

const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export default class ReservationDTO {
  constructor(item = {
    sent_emails: []
  }) {
    this.ID = item.id
    this.candidateNumber = item.number
    this.candidateBirthday = item.date_of_birth ? Dayjs(item.date_of_birth).format(DATE_FORMAT_DEFAULT) : null
    this.candidateName = item.name
    this.firstName = item.first_name
    this.lastName = item.last_name
    this.accountUsername = item.cbr_user
    this.status = item.cbr_status
    this.applicationId = item.application_id
    this.courseLocation = item.course_location
    this.course =  new CourseModel(item.course || {})
    this.courseDate = item.course_date ? Dayjs(item.course_date).format(DATE_FORMAT_DEFAULT) : null
    this.lastError = item.last_error;
    this.wasExchanged = item.was_exchanged;
    this.exchange_count = item.exchange_count;
    this.candidateEmail = item.email
    this.candidateTel = item.phone
    this.failed = item.failed
    this.confirmedCourses = item.confirmed_courses ? item.confirmed_courses.map(course => new CourseModel(course)) : []
    this.licenseType = item.license_type
    this.productName = item.product_name || PRODUCT.BTH
    this.lastError = item.last_error
    this.exam = new ReservedExamDTO(item.exam)
    this.notes = item.notes
    this.needProcessing = item.need_processing
    this.isWaitList = item.is_wait_list
    this.appPlacedAt = item.application_placed_at
    this.appPaidAt = item.application_paid_at
    this.address = item.address
    this.drivingSchool = item.driving_school
    this.sentEmails = item.sent_emails ? item.sent_emails.map(email => new SentEmailModel(email)) : []
    this.payments = item.payments ? item.payments.map(payment => new PaymentLog(payment)) : []
    this.paidAmount = item.paid_amount
    this.final_amount = item.final_amount
    this.refunded_amount = item.refunded_amount
    this.are_dates_fixed = item.are_dates_fixed
    this.color = item.color
    this.course_configuration = item.course_configuration
    this.exercises_access_expires_at = item.exercises_access_expires_at ? Dayjs(item.exercises_access_expires_at).format(DATE_TIME_FORMAT) : null
    this.unsubscribe_from_suggestions = item.unsubscribe_from_suggestions
    this.is_course_paid = item.is_course_paid
    this.recommended_payment = item.recommended_payment
    this.restore_after_exam = item.restore_after_exam
    this.exams = item.exams
    this.needs_approval = item.needs_approval
    this.has_approval = item.has_approval
    this.has_package = item.has_package
    this.package_name = item.package_name
    this.course_type = item.course_type

    this.onHold = item.is_on_hold
    this.noDates = item.no_dates
    this.inQueue = item.is_in_queue
    this.inExchangeQueue = item.is_in_exchange_queue
    this.noCandidateNumber = item.no_candidate_number
    this.acceptanceError = item.acceptance_error
    this.reservationError = item.reservation_error
    this.exchangeError = item.exchange_error
    this.exam_requests_total = item.exam_requests_total
    this.newReserve = item.new_reserve
    this.newExchange = item.new_exchange
    this.isMijnReservation = item.is_mijn_reservation
    this.mijn_exam_location = item.mijn_exam_location
    this.mijn_exam_datetime = item.mijn_exam_datetime ? item.mijn_exam_datetime : null
    this.force_reapply_allowed_until = item.force_reapply_allowed_until
    this.labels = item.labels || []
    this.willComeToCourse = item.will_come_to_course
    this.canceled = item.canceled
    this.wiki_access_days = item.wiki_access_days || 0
    this.wiki_access_from_date = item.wiki_access_from_date ? Dayjs(item.wiki_access_from_date).format(DATE_FORMAT_DEFAULT) : null
    this.wiki_access_days_left = item.wiki_access_days_left || 0
    this.online_access_days_left = item.online_access_days_left || 0
    this.reservation_days_offset = item.reservation_days_offset
    this.package_info = item.package_info
    this.has_package = item.has_package
    this.package_name = item.package_name
    this.package_starting_at = item.package_starting_at
    this.package_ending_at = item.package_ending_at
    this.package_paid_amount = item.package_paid_amount
    this.package_exams_only_at = item.package_exams_only_at

    if (item.exam_request_history) this.exam_request_history = item.exam_request_history.map(exam => new ReservationRequestDTO(exam)) || []

    if (!item.exam_reservation_requests || !item.exam_reservation_requests.length) this.requests = [new ReservationRequestDTO({added_manually: true})]
    else {
      this.requests = item.exam_reservation_requests.map(requestItem => new ReservationRequestDTO(requestItem))
      this.requests.forEach(requestItem => requestItem.courseLocation = requestItem.courseLocation ? requestItem.courseLocation : this.courseLocation)
    }
  }
}
