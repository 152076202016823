import { mapState } from 'vuex';
import { REFUNDED_STATUS } from "@/app/admin/modules/candidates/core/candidates-const";
import { PAYMENT_STATUS } from '@/models/paymentModel';
import candidateGlobalRowMixin from './candidateGlobalRow.mixin';

export default {
  mixins: [candidateGlobalRowMixin],

  data: () => ({
    candidateColorID: null,
  }),

  mounted() {
    this.setColorId(this.item.color);
  },

  watch: {
    'item.color'(value) {
      this.setColorId(value);
    }
  },

  computed: {
    ...mapState('crmCandidateColors', {
      colors: state => state.colors
    }),
    candidateColor() {
      if (this.candidateColorID) {
        const color = this.colors.find(color => color.id === this.candidateColorID)
        return color.hex_color_code ? color.hex_color_code : ''
      } else {
        return ''
      }
    },
    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size;
    },

    // it's fix for 3cx plugin on chrome
    candidateNumberSplicedOnTwoElement() {
      return this.spliceOnTwoElement(this.item.candidateNumber)
    },

    telSplicedOnTwoElement() {
      return this.spliceOnTwoElement(this.item.candidateTel)
    },

    cbrAccount() {
      return this.$store.getters['cbrUser/getNameByID'](this.item.accountUsername)
    },

    currentLabels() {
      if (!this.item.labels || !this.labels) return []
      return this.item.labels.map(labelID => {
        let sameLabel = this.labels.find(label => {
          if (label.ID === labelID) return label
        })
        if (sameLabel) return sameLabel
      })
    },
  },

  methods: {
    setColorId(colorId) {
      this.candidateColorID = colorId;
    },
    selectWithShiftkey({event, item, index}) {
      if (!this.activeItems.length || !event.shiftKey) return
      this.$emit('selectedWithShiftkey', {item: item, index: index})
    },

    copyData(model) {
      let data = model.getSpreadsheetData(this.$store)
      this.copy(data)
    },

    goToEdit({ID, expand, isExpanded}) {
      this.$emit('onGoToEdit', {expand, isExpanded})
    },

    getSentEmails() {
      return this.item.sentEmails
    },

    spliceOnTwoElement(number) {
      number = number + ''
      let numberArr = number.split('')
      let halfLength = Math.ceil(numberArr.length / 2);
      let leftSide = numberArr.splice(0, halfLength);
      let html = `<span>${leftSide.join('')}</span><span>${numberArr.join('')}</span>`
      return html
    }
  },
}
