import { REFUNDED_STATUS } from "@/app/admin/modules/candidates/core/candidates-const"

export default {
  computed : {
    isRefunded() {
      if(!this.item.payments.length) return false
      let isRefunded = false
      this.item.payments.forEach(payment => {
        if(isRefunded) return
        if(!payment.refunds.length) return
        isRefunded = payment.refunds.some(refund => refund.status === REFUNDED_STATUS)
      })
      return isRefunded
    }
  }
  
}