import { mapGetters, mapActions } from 'vuex';
import { getMultipleQueryValue } from '@/util';
import SortModel from './models/candidatesSortModel';
import CandidateBroadcast from './models/candidateBroadcast';
import { CANDIDATE_PAGE, FILTERS_ARGS } from './candidates-const';
import { COLOR_PAGE } from '@/util/const';
import PaginationModel from '@/models/paginationModel';

let controller = new AbortController();

export default {
  data: () => ({
    list: [],
    pagination: null,
    loading: false,
    primaryPagination: null,
    fullListIds: [],
    activeItems: [],
    selectedItem: null,
    sortModel: null,
    currentPage: null,
    sortBy: [],
    sorting: [],
    expanded: [],
    listPromiseStarted: false,
    FILTERS_ARGS,
    COLOR_PAGE: COLOR_PAGE.CANDIDATES
  }),

  inject: ['svc'],

  computed: {
    ...mapGetters({
      labels: 'labels/items'
    }),

    showSelectAll() {
      return this.activeItems.length && this.pagination && this.pagination.count > this.pagination.size;
    },

    activeItemsLength() {
      return this.activeItems.length;
    },

    isActiveLabels() {
      let label = getMultipleQueryValue(this.$route.query[FILTERS_ARGS.LABEL]);
      let labelExclude = getMultipleQueryValue(this.$route.query[FILTERS_ARGS.LABEL_EXCLUDE]);
      let customLabel = getMultipleQueryValue(this.$route.query[FILTERS_ARGS.CUSTOM_LABELS]);
      let customLabelExclude = getMultipleQueryValue(this.$route.query[FILTERS_ARGS.CUSTOM_LABELS_EXCLUDE]);
      return !(!label.length && !labelExclude.length && !customLabel.length && !customLabelExclude.length);
    }
  },

  async created() {
    await this.init();
  },

  async mounted() {
    await this.setDefaultPagination();
    await this.loadCandidateColors();
    await this.loadData();
  },

  methods: {
    ...mapActions({
      getExamLocations: 'crmExamLocation/list',
      loadCandidateColors: 'crmCandidateColors/colorsList',
      getCbrUsers: 'cbrUser/list'
    }),

    async init() {
      this.initSortModel();
      await this.initCandidateBroadcast();
    },

    initSortModel() {
      this.sortModel = new SortModel(this.$route.query);
    },

    async initCandidateBroadcast() {
      let tokens
      tokens = await this.$auth.getTokensFromRepoOrRefresh();
      this.broadcast = new CandidateBroadcast(null, tokens.access);
      this.broadcast.startListen(this.getBroadcastData);
    },

    getBroadcastData() {
      return this.list;
    },

    async loadData() {
      await Promise.all([this.loadList({}), this.getCbrUsers({cache: true})]);
    },

    async _loadList({more = false, page = CANDIDATE_PAGE.TASKS}) {
      if (this.loading) return true

      if (this.listPromiseStarted) controller.abort();
      this.currentPage = page;
      try {
        this.loading = true;
        this.listPromiseStarted = true;
        let {results, pagination, candidates_counts} = await new Promise(async (resolve, reject) => {
          controller.signal.addEventListener('abort', reject);
          return resolve(await this.svc().list(this.$route.query, page));
        });
        this.listPromiseStarted = false;
        this.pagination = new PaginationModel(this.setPaginationParams(pagination));
        if (more) results.forEach((item) => setTimeout(() => this.list.push(item), 0));
        else {
          this.list = [];
          results.forEach((item, index) => setTimeout(() => this.list.push(item), 0));
          this.setPrimaryPagination();
        }
        this.$emit('candidatesCountsChanged', candidates_counts);
        this.clearFullListIds();
      } catch (error) {
        console.log(error);
        this.listPromiseStarted = false;
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM});
      } finally {
        this.loading = false;
      }
    },

    async toggleSelectAllItems({clear}) {
      if (clear) this.activeItems = [];
      else {
        if (!this.fullListIds.length) {
          await this._loadList({page: this.currentPage});
          await this._getFullListIds();
        }
        this.activeItems = this.fullListIds;
      }
    },

    async _getFullListIds() {
      try {
        this.loading = true;
        const res = await this.svc().fullListIds({ids_list: true, ...this.$route.query}, this.currentPage);
        // console.log(res);
        this.fullListIds = res;
      } catch (error) {
        console.log(error);
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM});
      } finally {
        this.loading = false;
      }
    },

    setPrimaryPagination() {
      this.primaryPagination = {
        page: this.pagination.page,
        size: this.pagination.size
      };
    },

    async paginationChange() {
      await this.setPaginationQuery();
      this.loadList({});
    },

    async onLoadMoreClick() {
      this.pagination.loadMore();
      await this.setPaginationQuery();
      this.loadList({more: true});
    },

    async setPaginationQuery() {
      await this.$router.replace({query: {...this.$route.query, page: this.pagination.page, size: this.pagination.size}});
    },

    goToEdit({expand, isExpanded}) {
      try {
        if (this.$refs.expand && this.$refs.expand.model && this.$refs.expand.model.editing) {
          let goToEdit = () => {
            setTimeout(() => {
              expand(!isExpanded);
            }, 0);
          };
          this.$refs.expand.goBackHandler(goToEdit);
          return;
        }
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM});
        return Promise.reject(error);
      }
      expand(!isExpanded);
    },

    hideExpandBy(expand) {
      expand(false);
    },

    async markAsProcessed() {
      try {
        let ids = this.activeItems.map(item => item.ID);
        let res = await this.Svc().markAsProcessed(ids);
        this.loadList({});
        return res;
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM});
        return Promise.reject(error);
      }
    },

    async updateSorting() {
      let data = this.sortModel.getApiData(this.$route.query);
      let ordering;
      if (data.ordering.length) ordering = data.ordering;
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ordering: ordering}});
      this.loadList({});
    },

    copyMultiple() {
      let activeItemsIndex = this.activeItems.map(item => this.list.findIndex(_item => _item.ID === item.ID)).sort((a, b) => a - b);
      let data = activeItemsIndex.map(index => this.list[index].getSpreadsheetData(this.$store));
      this.copy(data.join('\n'));
    },

    updateTableDataBy(item, candidate) {
      item.merge(candidate);
    },

    extendedSelection({item, index}) {
      let activeItemsIndex = this.activeItems.map(item => this.list.findIndex(_item => _item.ID === item.ID));
      let itemUpperToSelectedIndex = -1;
      activeItemsIndex.forEach(activeIndex => {
        if ((index > activeIndex) && (itemUpperToSelectedIndex < activeIndex)) itemUpperToSelectedIndex = activeIndex;
      });
      if (itemUpperToSelectedIndex < 0) return;
      for (let i = itemUpperToSelectedIndex + 1; i < index; i++) {
        this.activeItems.push(this.list[i]);
      }
    },

    paymentsOpen(data) {
      this.$refs.payments.paymentsOpen(data);
    },

    updateActiveItemsHold(status) {
      this.activeItems.forEach(item => {
        item.requests.forEach(request => {
          request.onHold = status;
        });
      });
    },

    clearFullListIds() {
      this.fullListIds = [];
    }
  },

  watch: {
    '$route.query': function () {
      this.activeItems = [];
    }
  },
};
