<template lang="pug">
  span(:class="classes").status-box
    .status-box__item(:style="{backgroundColor: _color}" )
</template>

<script>
export default {
  props: {
    color: String,
    classes: String
  },

  computed: {
    _color() {
      switch (this.color) {
      case 'success':
        return '#1c7e48'

      case 'error':
        return '#b90101'

      case 'warning':
        return '#fb8d03'

      default:
        return this.color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.status-box {
  display: inline-block;

  &__item {
    width: 7px;
    display: inline-block;
    height: 7px;
    border-radius: 50%;

    &_success {
      background-color: #1c7e48;
    }

    &_error {
      background-color: $error-check-color;
    }
  }
}
</style>
