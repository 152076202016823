<template lang="pug">
div
  v-data-table(
    :headers="headers"
    :items="list"
    no-data-text="No emails"
    hide-default-header
    hide-default-footer
    :loading="loading"
    class="app-table"
  )
    template(v-slot:item="{item, index}")
      tr(:key="index")
        td(:class="{'archived': item.isArchived}") {{ item.datetime }}
        td(:class="{'archived': item.isArchived}") {{ item.label }}
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    loading: Boolean
  },
  
  data:() => ({
    headers: [
      { text: 'Date & type', value: 'datetime', sortable: false },
      { text: 'Label', value: 'label', sortable: false },
    ]
  })
}
</script>

<style lang="scss" scoped>
.app-table .archived {
  color: $log-default;
}
</style>