import store from "@/store";

export default class EmailTemplatesStoreRepo {
  constructor(store) {
  }
  async list(params) {
    await store.dispatch('emailTemplates/list', params)
    return store.getters['emailTemplates/items']
  }

  async listCustom(params) {
    await store.dispatch('emailTemplates/listCustom', params)
    return store.getters['emailTemplates/itemsCustom']
  }

  async post(data) {
    let res = await store.dispatch('emailTemplates/post', data)
    return res
  }

  async put(ID, data) {
    return await store.dispatch('emailTemplates/put', {ID, data})
  }

  async remove(data) {
    return await store.dispatch('emailTemplates/remove', data)
  }
}