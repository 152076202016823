<template lang="pug">
div
  .candidate-label.candidate-label_no-dates(v-if="model.noDates") no dates
  .candidate-label.candidate-label_cdn(v-if="model.noCandidateNumber") no CDN
  .candidate-label.candidate-label_reservation-e(v-if="model.reservationError") reservation error
  .candidate-label.candidate-label_acceptance-e(v-if="model.acceptanceError") acceptance error
  .candidate-label.candidate-label_exchange-e(v-if="model.exchangeError") exchange error
  .candidate-label.candidate-label_queue(v-if="model.inQueue") in queue
  .candidate-label.candidate-label_exchange-queue(v-if="model.inExchangeQueue") exchange queue
  .candidate-label.candidate-label_exchange(v-if="model.newExchange") new exchange
  .candidate-label.candidate-label_reserve(v-if="model.newReserve") new reserve
  .candidate-label.candidate-label_mijn-reservation(v-if="model.isMijnReservation") mijn reservation
  .candidate-label.candidate-label_need-approval(v-if="model.needs_approval") need approval
  .candidate-label.candidate-label_has-approval(v-if="model.has_approval") has approval

</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-label {
  display: inline-block;
  font-size: 8px;
  margin-right: 3px;
  font-weight: bold;
  height: 12px;
  padding: 2px;
  color: #fff;
  background-color: $error-color;
  border-radius: 2px;
  &_hold {
    background-color: $error-color;
  }
  &_no-dates {
    background-color: $warning-color;
  }
  &_cdn {
    background-color: $label-color;
  }
  &_reservation-e {
    background-color: darken($color: $error-color, $amount: 30%);
  }

  &_acceptance-e {
    background-color: darken($color: $error-color, $amount: 50%);
  }
  &_acceptance-e {
    background-color: darken($color: $error-color, $amount: 50%);
  }
  &_exchange-e {
    background-color: #8a0e67;
  }
  &_exchange-queue {
    background-color: #fc03b6;
  }
  &_queue {
    background-color: #cfe815;
  }
  &_reserve {
    background-color: $success-check-color;
  }
  &_exchange {
    background-color: darken($color: $success-check-color, $amount: 30%);
  }
  &_mijn-reservation {
    background-color: #3c16e9;
  }
  &_bad-exam {
    background-color: #ff0000;
  }
  &_need-approval {
    background-color: #EB5A8E;
  }
  &_has-approval {
    background-color: #A570DD;
  }
}
</style>
