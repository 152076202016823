<template lang="pug">
  span.table-reservation(:class="{'have-slot' : !request.onHold && request.slotsCount, 'hold': request.onHold }")
    template(v-if="request.slotsCount !== null && request.slotsCount !== undefined")
      span(v-html="request.slotsCount + ' | ' + request.examDate + ' | ' + request.location")

    v-menu(
      v-if="index === 1 && examRequestsTotal > 2"
      v-model="menuShowing"
      max-width="185px"
      offset-y
      bottom
      content-class="v-menu__content"
    )
      template(v-slot:activator="{ on }")
        div(v-on="on" @click.stop="loadData").show-all-text.unselectable show all
      v-list
        v-list-item(v-if="loading").text-center
          v-progress-circular(indeterminate size=32 color="warning")
        v-list-item(v-for="(fullRequest, fullIndex) in list" :key="fullIndex").auto-min
          v-list-item-title.table-reservation(:class="{'have-slot' : !fullRequest.onHold && fullRequest.slotsCount, 'hold': fullRequest.onHold }")
            template(v-if="fullRequest.slotsCount !== null && fullRequest.slotsCount !== undefined")
              span(v-html="fullRequest.slotsCount + ' | ' + fullRequest.examDate + ' | ' + fullRequest.location")
</template>

<script>
import CandidatesActionsService from '../../core/candidates-actions-service'
import ReservationRequestDTO from '../../core/DTO/reservationRequestDTO'
import errorsMixin from '@/mixins/errors.mixin'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  name: 'CandidatesReservedDate',

  mixins: [errorsMixin, featureFlagsMixin],

  props: {
    request: {
      type: Object,
      required: true
    },
    filteredRequests: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    },

    examRequestsTotal: {
      type: Number
    },

    ID: {
      type: Number
    }
  },

  data: () => ({
    menuShowing: false,
    list: [],
    loading: false
  }),

  methods: {
    async loadData() {
      try {
        if (this.list.length) return
        this.loading = true
        let res = await new CandidatesActionsService().examRequests(this.ID)
        this.list = res.map(item => new ReservationRequestDTO(item))
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auto-min {
  min-height: auto;
}

.table-reservation {
  font-size: 10px;
  display: inline-block;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $title-second;
  line-height: 1.3;

  &__more {
    color: $primary-color;
    cursor: pointer;
  }
}

.have-slot {
  color: #24a255;
}

.hold {
  color: $error-color;
}
</style>
