<template lang="pug">
  span 
    slot(name="datename") {{ item.dateAndName }}
    |  - {{ cityName }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    }),

    cityName() {
      let city = this.cities.find(city => city.ID === this.item.city)
      return city.name
    }
  },
}
</script>
