<script>
import EmailTemplate from "@/app/admin/modules/candidates/core/models/CandidatesEmailTemplateModel"
import Svc from "@/services/emailTemplatesService";
import EmailTemplatesStoreRepo from "@/models/EmailTemplatesStoreRepo"
import SentEmailModel, { SENT_EMAIL_ARG } from '../../core/models/candidatesSentEmailModel';
import FormErrors from '@/util/form-errors';
import CandidatesEmailTemplateValidator from "../../core/models/candidatesEmailTemplateValidator"
import errorsMixin from '@/mixins/errors.mixin';

export default {
  render() {
    return this.$scopedSlots.default({
      list: this.list,
      listLoading: this.listLoading,
      editedTemplate: this.editedTemplate,
      logs: this.logs,
      loadData: this.loadData,
      loadTemplateData: this.loadTemplateData,
      loadCreateTemplate: this.loadCreateTemplate,
      sendEmail: this.sendEmail,
      loadLogs: this.loadLogs,
      post: this.post,
      update: this.update,
      remove: this.remove,
      formErrors: this.formErrors,
      processing: this.processing,
      setTemplateTooltipShowing: this.setTemplateTooltipShowing
    })
  },

  inject: {
    parentSvc: 'svc'
  },

  mixins: [errorsMixin],

  props: {
    ID: Number,
    getLogs: Function,
    model: Object
  },

  data: () => ({
    svc: new Svc(new EmailTemplatesStoreRepo()),
    list: [],
    logs: [],
    listLoading: false,
    editedTemplate: new EmailTemplate(),
    formErrors: new FormErrors(),
    templateValidator: new CandidatesEmailTemplateValidator(),
    processing: false,
    templateTooltip: false
  }),

  methods: {
    async loadData(params = {cache: false}) {
      this.listLoading = true
      let [list, customList] = await Promise.all([this.loadList({cache: params.cache}), this.loadCustomList({cache: params.cache})])
      this.list = [...list,...customList]
      this.loadLogs()
      this.listLoading = false
    },

    async loadList(params = {cache: false}) {
      try {
        return await this.svc.list(params)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async loadCustomList(params = {cache: false}) {
      try {
        return await this.svc.listCustom(params)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    loadTemplateData(item) {
      this.editedTemplate = item
    },

    loadCreateTemplate() {
      this.editedTemplate = new EmailTemplate()
    },

    async sendEmail(data, callback) {
      if(!this.isRequiredDataFilled(data)) return
      try {
        if(!data.readonly && !this.templateValidator.validate(this.formErrors, data)) {
          this.$notify({text: 'Invalid fields', type: 'error'})
          return
        }
        this.processing = true
        let res = await this.parentSvc().sendEmail(this.ID, data)
        callback()
        this.$notify({text: 'Email send', type: 'success'})
        this.logs.unshift(new SentEmailModel(
          {
            [SENT_EMAIL_ARG.LABEL]: data.label,
            [SENT_EMAIL_ARG.DATETIME]: Date.now()
          }
        ))
        return res
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.templateValidator.apiAttributes,
        });
        this.$notify({text: 'Sending error', type: 'error'})
      }
      finally {
        this.processing = false
      }
    },

    isRequiredDataFilled(data) {
      let valid = true
      if (!this.model.candidateEmail) {
        valid = false
        this.$notify({text: 'Сandidate has no mail', type: 'error'})
      }
      return valid
    },

    async post(data) {
      try {
        if(!this.templateValidator.validate(this.formErrors, data)) {
          this.$notify({text: 'Invalid fields', type: 'error'})
          return
        }
        this.processing = true
        let res = await this.svc.post(data)
        this.$notify({text: 'Template created', type: 'success'})
        this.list.push(res)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.templateValidator.apiAttributes,
        });
        this.$notify({text: 'Sending error', type: 'error'})
      }
      finally {
        this.processing = false
      }
    },

    async update(data) {
      try {
        if(!this.templateValidator.validate(this.formErrors, data)) {
          this.$notify({text: 'Invalid fields', type: 'error'})
          return
        }
        this.processing = true
        let res = await this.svc.put(data.ID, data)
        this.$notify({text: 'Template updated', type: 'success'})
        let deletedItemIndex = this.list.findIndex(item => item.ID === data.ID)
        if(deletedItemIndex >= 0) this.list[deletedItemIndex].merge(data)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.templateValidator.apiAttributes,
        });
        this.$notify({text: 'Sending error', type: 'error'})
      }
      finally {
        this.processing = false
      }
    },

    async remove(data) {
      try {
        this.processing = true
        await this.svc.remove(data.ID)
        this.$notify({text: 'Template removed', type: 'success'})
        let deletedItemIndex = this.list.findIndex(item => item.ID === data.ID)
        if(deletedItemIndex >= 0) this.list.splice(deletedItemIndex, 1)
      } catch (error) {
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.templateValidator.apiAttributes,
        });
        this.$notify({text: 'Sending error', type: 'error'})
      }
      finally {
        this.processing = false
      }
    },

    loadLogs() {
      this.logs = this.getLogs()
    },

    setTemplateTooltipShowing(val) {
      this.templateTooltip = val
      this.loadLogs()
    }
  },
}
</script>
