import { dateFormating } from '@/util';
import apiActions from '@/services/api/api-candidates-actions';
import HandleFailedModel from '@/app/admin/modules/candidates/core/models/candidatesHandleFailedModel';

export default class CandidatesActionsService {
  constructor() {
    this.api = apiActions;
  }

  async quickReserve(city, ids, data) {
    try {
      let apiData = {
        ids,
        city,
        location: data.location,
        exam_date: dateFormating(data.date).join('-'),
        exam_time_from: data.timeFrom,
        exam_time_to: data.timeTo,
        is_exchange: data.isExchange,
      };
      let res = await this.api.quickReserve(apiData);
      return {
        failedCandidates: res.failed_candidates.map(item => new HandleFailedModel(item)),
        error: res.error
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async examRequests(ID) {
    try {
      return await this.api.examRequests(ID);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async bulkAssignTransportType(ids, courseDayID, formData) {
    try {
      return await this.api.bulkAssignTransportType({
        ids: ids,
        course_day_id: courseDayID,
        transport_type: formData.transport_type
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendCertificateEmail(formData) {
    try {
      return await this.api.sendCertificateEmail({
        ...formData,
        exam_date: dateFormating(formData.exam_date).join('-')
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async uploadCoursePhoto(ids, data) {
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const formData = new FormData();

    for (let index = 0; index < ids.length; index++) {
      formData.append(`ids[${ index }]`, ids[index]);
    }
    formData.append('course_photo', data);

    try {
      return await this.api.uploadCoursePhoto(formData, config);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async removeCoursePhoto(ID) {
    try {
      return await this.api.removeCoursePhoto({ids: [ID]});
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async bulkDownloadCoursePhoto(ids, consentOny) {
    const config = { responseType: 'blob' };

    try {
      return await this.api.bulkDownloadCoursePhoto({ ids: ids, consent_only: consentOny  }, config);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
