var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"app-table",attrs:{"headers":_vm.headers,"items":_vm.list,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var select = ref.select;
var isSelected = ref.isSelected;
var index = ref.index;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('div',{staticClass:"contents"},[_c('tr',[_c('td',{staticClass:"paid",class:{'archived': item.isArchived}},[_vm._v(_vm._s(_vm._f("formattedDatetime")(item.createdAt))+" / "+_vm._s(_vm._f("formattedDatetime")(item.paidAt)))]),_c('td',{staticClass:"amount",class:{'archived': item.isArchived}},[_vm._v(_vm._s(item.label)+" "+_vm._s(item.amount))]),_c('td',{staticClass:"status",class:{'archived': item.isArchived}},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center notes",class:{'archived': item.isArchived}},[(item.notes)?_c('show-more',{attrs:{"text":item.notes,"height":'40px'}}):_c('span',[_vm._v("-")])],1),_c('td',{staticClass:"action",class:{'archived': item.isArchived}},[_vm._t("actions",null,{"payment":item,"isPaid":item.status === _vm.PAID})],2)]),(item.refunds.length)?_vm._l((item.refunds),function(refund){return _c('tr',{staticClass:"bg-second"},[_c('td',{staticClass:"pb-0",attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cell-paid"},[_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm._f("formattedDatetime")(refund.createdAt)))])]),_c('div',{staticClass:"cell-amount mr-3 text-center"},[_vm._v(_vm._s(refund.label)+" -"+_vm._s(refund.amount))]),_c('div',{staticClass:"cell-status"},[_vm._v(_vm._s(refund.status))])])])])}):_vm._e()],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }